html, body {
  height: 100%;
}

body {
  padding-top: 40px;
  padding-bottom: 40px;
}

.form-signin {
  box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.20);
  border-radius:5px;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.forgotten-pw {
  text-align: left;
  padding-top:10px;
}

.form-signin .form-signin-heading,
.form-signin label {
  padding-bottom: 10px;
}

.form-signin label {
  padding-top: 10px;
}

.form-signin label {
  font-weight: normal;
}

.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin .username-addon {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.form-signin .password-addon,
.form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-signin #login-alert {

}

.fadeIn.ng-enter {
  transition:0.3s linear all;
  opacity:0;
}

.fadeIn.ng-enter.ng-enter-active {
  opacity:1;
}

table thead th:last-of-type {
  text-align:right;
}

table tr td:last-of-type {
  text-align: right;
}

h1 button {
  float: right;
}

/*
 * Base structure
 */

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-top: 50px;
}


/*
 * Global add-ons
 */

.sub-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
  border: 0;
}

/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar {
  display: none;
}
@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background-color: #f5f5f5;
    border-right: 1px solid #eee;
  }
}

/* Sidebar navigation */
.nav-sidebar {
  margin-right: -21px; /* 20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px;
}
.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
}
.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: #fff;
  background-color: #428bca;
}

.preview {
  text-align: center;
}

#bannerSrc {
  height: 250px;
}

#pricing {
  width: 90%;
}

#pricingType {
  width: 10%;
}

#limit {
  width: 85%;
}

#limitType {
  width: 15%;
}


#frequencyInterval {
  width: 15%;
}

.normal {
  width:70%;
}

.extended {
  width:85%;
}

#frequencyIntervalType {
  width: 15%;
}

/*
 * Main content
 */

.main {
  padding: 20px;
  background-color: rgb(41, 41, 41);
}
@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.main .page-header {
  margin-top: 0;
}

.datetimepicker-wrapper {
  vertical-align: middle;
  display: block;
}

.datetimepicker-wrapper .form-control {
  width:100%;
}

.datetimepicker-wrapper[name=timepicker] {
  display:none;
  float:left;
}

.input-xs {
  height: 22px;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.footable-row-detail-name {
  text-align:left;
}

.footable-row-detail-value {
  text-align:right;
}

.footable-row-detail-inner {
  width: 100%;
}